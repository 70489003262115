import { AxiosResponse } from "axios";
import { ICreateCompanyDto } from "../modules/companies/dto";
import { ICompany } from "../modules/companies/interface";
import { urlMaker } from "../utils";
import { httpClient } from "./config";

export const companyApi = {
  getCompanies: (filters?: { [key: string]: any }) =>
    httpClient.get(urlMaker("/companies/company/", filters)),

  createCompany: (data: ICreateCompanyDto) =>
    httpClient.post("/companies/company/", data),

  getSingleCompany: (id: string | number): Promise<AxiosResponse<ICompany>> =>
    httpClient.get(`/companies/company/${id}/`),

  updateCompany: (
    id: string | number,
    data: any
  ): Promise<AxiosResponse<ICompany>> =>
    httpClient.put(`/companies/company/${id}/`, data),

  getRatingsByCompany: (id: string | number) =>
    httpClient.get(`/companies/company_rating_values/${id}/`),

  getRatingsConsolidationCompanies: (
    company_id: string | number,
    rate_id: string | number
  ) =>
    httpClient.get(
      urlMaker(`/ratings/consolidation/`, { company_id, rate_id })
    ),
};
