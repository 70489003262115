import { Fragment, MouseEvent, useCallback } from "react";
import { NavLink } from "react-router-dom";
import styles from "./sidebar.module.scss";
import { Strings } from "../../constants/Strings";
import RatingsIcon from "././../../assets/icons/ratings.svg";
import CompaniesIcon from "././../../assets/icons/companies.svg";
import GlossaryIcon from "././../../assets/icons/glossary.svg";
import GroupCompaniesIcon from "././../../assets/icons/group_companies.svg";
import IndustriesIcon from "././../../assets/icons/industries.svg";
import UsersIcon from "././../../assets/icons/users.svg";
import LogoutIcon from "././../../assets/icons/logout.svg";
import CountriesIcon from "././../../assets/icons/country.svg";
import ValuesIcon from "././../../assets/icons/values.svg";
import PashaLogoEN from "./../../assets/img/pasha_logo_eng.png";
import { getUser, logout, useDispatch, useSelector } from "../../redux";

const Sidebar = () => {
  const { user_data } = useSelector(getUser);

  const dispatch = useDispatch();

  const onLogoutHandler = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      dispatch(logout());
    },
    [dispatch]
  );

  return (
    <div
      className={`${styles.sidebar} dflx-c  justify_content_space_between brdr_right`}
    >
      <div className="dflx_r w_10">
        <div className={`py_1 px_2 brdr_bottom dflx-r ${styles.logo}`}>
          <img src={PashaLogoEN} alt="logo" />
        </div>
        <ul className="dflx-c align_items_start py_2 px_2">
          <li className="w_10">
            <NavLink
              to="/ratings"
              className={({ isActive }) =>
                `${isActive && styles.active_link} py_2 pl_1 dflx-r`
              }
            >
              <img className="mr_1" src={RatingsIcon} alt="overview icon" />
              <p>{Strings.ratings}</p>
            </NavLink>
          </li>
          <li className="w_10">
            <NavLink
              to="/companies"
              className={({ isActive }) =>
                `${isActive && styles.active_link} py_2 pl_1 dflx-r`
              }
            >
              <img className="mr_1" src={CompaniesIcon} alt="companies icon" />
              <p>{Strings.companies}</p>
            </NavLink>
          </li>
          <li className="w_10">
            <NavLink
              to="/group-companies"
              className={({ isActive }) =>
                `${isActive && styles.active_link} py_2 pl_1 dflx-r`
              }
            >
              <img
                className="mr_1"
                src={GroupCompaniesIcon}
                alt="group companies icon"
              />
              <p>{Strings.groupCompanies}</p>
            </NavLink>
          </li>
          <li className="w_10">
            <NavLink
              to="/industries"
              className={({ isActive }) =>
                `${isActive && styles.active_link} py_2 pl_1 dflx-r`
              }
            >
              <img
                className="mr_1"
                src={IndustriesIcon}
                alt="industries icon"
              />
              <p>{Strings.industries}</p>
            </NavLink>
          </li>
          <li className="w_10">
            <NavLink
              to="/glossary"
              className={({ isActive }) =>
                `${isActive && styles.active_link} py_2 pl_1 dflx-r`
              }
            >
              <img className="mr_1" src={GlossaryIcon} alt="glossary icon" />
              <p>{Strings.glossary}</p>
            </NavLink>
          </li>
          {user_data?.role === "system_admin" ||
            (user_data?.role === "admin" && (
              <li className="w_10">
                <NavLink
                  to="/apartment-evaluation"
                  className={({ isActive }) =>
                    `${isActive && styles.active_link} py_2 pl_1 dflx-r`
                  }
                >
                  <img
                    className="mr_1"
                    src={GlossaryIcon}
                    alt="glossary icon"
                  />
                  <p>{Strings.apartmentEvaluation}</p>
                </NavLink>
              </li>
            ))}
          {user_data && user_data.role === "admin" && (
            <Fragment>
              <li className="w_10">
                <NavLink
                  to="/countries"
                  className={({ isActive }) =>
                    `${isActive && styles.active_link} py_2 pl_1 dflx-r`
                  }
                >
                  <img
                    className="mr_1"
                    src={CountriesIcon}
                    alt="glossary icon"
                  />
                  <p>{Strings.countries}</p>
                </NavLink>
              </li>
              <li className="w_10">
                <NavLink
                  to="/calibration"
                  className={({ isActive }) =>
                    `${isActive && styles.active_link} py_2 pl_1 dflx-r`
                  }
                >
                  <img className="mr_1" src={ValuesIcon} alt="values icon" />
                  <p>{Strings.calibration}</p>
                </NavLink>
              </li>
            </Fragment>
          )}
          {user_data?.role === "system_admin" && (
            <li className="w_10">
              <NavLink
                to="/users"
                className={({ isActive }) =>
                  `${isActive && styles.active_link} py_2 pl_1 dflx-r`
                }
              >
                <img className="mr_1" src={UsersIcon} alt="users icon" />
                <p>{Strings.users}</p>
              </NavLink>
            </li>
          )}
        </ul>
        <div className={`${styles.logout} w_10 px_2`}>
          <a
            onClick={onLogoutHandler}
            href="#null"
            className="false py_2 pl_1 dflx-r"
          >
            <img className="mr_1" src={LogoutIcon} alt="logout icon" />
            <p>Logout</p>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
