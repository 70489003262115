export const Strings = {
  overview: "Overview",
  companies: "Companies",
  groupCompanies: "Group Companies",
  industries: "Industries",
  users: "Users",
  ratings: "Ratings",
  values: "Values",
  glossary: "Glossary",
  government: "Government",
  financialHistory: "Financial History",
  ratingHistory: "Rating History",
  countries: "Countries",
  calibration: "Calibration",
  sheets: "Sheets - Work Table",
  apartmentEvaluation: "Apartment evalutation",
};
